import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { TurnosService } from '../_services/turnos.service';
import { MercadoPagoService } from '../_services/mercado-pago.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogAutorizarCoberturaComponent } from '../dialog-autorizar-cobertura/dialog-autorizar-cobertura.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { error } from 'console';

export interface TurnoAutorizar {
  matricula: number;
  fecha: string;
  desdeHora: string;
  orden: number;
  pinSeguridad: number;
  servicio: string;
  nroConsultorio: string;
}

export interface MutualTurno {
  matricula: number;
  fecha: string;
  desdeHora: string;
  orden: number;
  idMutual: number;
  nroCarnet: string;
  pinSeguridad: number;
  importe: number;
  usuario: string;
}

export interface Mutual {
  matricula: number;
  mutual: number;
  nombreMutual: string;
  nroCarnet: string;
  fechaUltimoUso: string;
  webCheckIn: string;
}

export interface Paciente {
  apellido: string;
  usuario: string;
}

export interface Boleta {
  usuario: string;
  matricula: number;
  fecha: string;
  desdeHora: string;
  orden: number;
  importe: number;
}

export interface MercadoPago {
  nroBoleta: number;
  importeTotal: string;
}

@Component({
  selector: 'app-autorizar-cobertura',
  templateUrl: './autorizar-cobertura.component.html',
  styleUrls: ['./autorizar-cobertura.component.css'],
})

export class AutorizarCoberturaComponent implements OnInit {

  mutualForm: FormGroup = new FormGroup({
    nombreMutual: new FormControl(),
    nroCarnet: new FormControl(),
    pinSeguridad: new FormControl(),
  });

  mutuales;
  mutualElegida;
  nombreMutual;
  nroCarnet: string;
  pac: Paciente;
  nombreApellido: string;
  especialidad: string;
  profesional: string;
  fechaHora: string;
  autorizacion: boolean;
  cargando: boolean = true;
  turnoAutorizar: any;
  boletaCrear = {} as Boleta;
  mutualTurno = {} as MutualTurno;
  mercadoPago = {} as MercadoPago;
  nroBono;
  mensajeAutorizar: string;
  tieneWebCheckin: boolean;
  esConsultorio: boolean;
  linkTeleconsulta: String;
  importeTotal: number = 0;
  urlPagar: string = 'null';
  msjeValParticular: string;
  valorizacionParticular: boolean = false;
  habilitarMsjeMutualProf: boolean = true;
  msjeMutualProf: string;
  esFinanciador: boolean;
  msjeMutualDeshabilitada: String;
  pinSeguridad: number;
  estaReintentando: boolean;
  hayPago: boolean = false;
  urlPagoMP: string;
  existeMsjeMutualDeshabilitada: boolean;
  invalidForm: boolean = false;
  msjeInvalidForm: string;
  loading: boolean = true;

  constructor(
    private router: Router,
    private userService: UserService,
    private turnoService: TurnosService,
    private mercadoPagoService: MercadoPagoService,
    private urlResolverService: UrlResolverService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {

  }

  ngOnInit() {
    this.getUrlPago();

    this.obtenerDatosTurno();
  }

  getUrlPago() {
    this.urlResolverService.getUrlPago().toPromise().then((respGetUrlPago) => {
      // console.log(respGetUrlPago)
      if (respGetUrlPago.ok) {
        this.urlPagoMP = respGetUrlPago.mensaje;
      }
    }, (error) => {
      console.log(error);
    });
  }

  // ANALIZO MUTUAL Y SI EL PROFESIONAL ATIENDE POR X MUTUAL
  async verificarMutualProf(mutualElegida) {
    // console.log(mutualElegida);
    this.loading = true;
    this.mutualForm = this.formBuilder.group({
      nombreMutual: [ mutualElegida, Validators.required],
      nroCarnet: [ mutualElegida.nroCarnet, Validators.required],
      pinSeguridad: [null]
    });

    if (mutualElegida.codigoSeguridad === 'S') {         // MUTUALES QUE REQUIEREN PIN DE SEGURIDAD
      this.esFinanciador = true;
      this.mutualForm.get('pinSeguridad').addValidators(Validators.required);
    } else {
      this.esFinanciador = false;
      this.mutualForm.get('pinSeguridad').removeValidators;
    }

    if (mutualElegida.webCheckIn == 'N') {      // MUTUALES NO HABILITADAS
      this.existeMsjeMutualDeshabilitada = false;
      this.msjeMutualDeshabilitada = 'La mutual seleccionada no esta habilitada para la Admisión Digital.'
    } else if (mutualElegida.financiador == 55) {      // MUTUALES PARA SOCIOS
      await this.userService.obtenerDeudaSocio().toPromise().then(async rpGetDeudaSocio => {
        // console.log(rpGetDeudaSocio)
        if (rpGetDeudaSocio) {
          if (Number.parseInt(rpGetDeudaSocio.cuentaCorriente.saldo) > 0 && rpGetDeudaSocio.cuentaCorriente.movimientosSinImputar.length > 1) { // SI TIENE DEUDA Y DEBE MAS DE UNA CUOTA
            this.existeMsjeMutualDeshabilitada = false;
            this.msjeMutualDeshabilitada = 'Para poder elegir esta mutual tenés que saldar la deuda pendiente.'
          } else {
            await this.turnoService.verificarMutualProf(this.turnoAutorizar.matricula, mutualElegida.mutual).toPromise().then((response) => {
              // console.log(response)
              this.existeMsjeMutualDeshabilitada = response.ok;
              if (!this.existeMsjeMutualDeshabilitada) {
                this.msjeMutualDeshabilitada = response.mensaje;
              }
            }, (error) => {
              console.log(error);
              this.existeMsjeMutualDeshabilitada = false;
              this.msjeMutualDeshabilitada = 'Ocurrio un error. No se pudo verificar si el profesional atiende por la mutual seleccionada.'
            });
          }
        } else {
          this.existeMsjeMutualDeshabilitada = false;
          this.msjeMutualDeshabilitada = 'Ocurrio un error (Socio no encontrado)'
        }
      }, error => {
        console.log(error)
        this.existeMsjeMutualDeshabilitada = false;
        this.msjeMutualDeshabilitada = 'Ocurrio un error (Socio no encontrado)'
      })
    } else {        // VERIFICAR SI EL PROF ATIENDE POR X MUTUAL
      await this.turnoService.verificarMutualProf(this.turnoAutorizar.matricula, mutualElegida.mutual).toPromise().then((response) => {
        // console.log(response)
        this.existeMsjeMutualDeshabilitada = response.ok;
        if (!this.existeMsjeMutualDeshabilitada) {
          this.msjeMutualDeshabilitada = response.mensaje;
        }
      }, (error) => {
        console.log(error);
        this.existeMsjeMutualDeshabilitada = false;
        this.msjeMutualDeshabilitada = 'Ocurrio un error. No se pudo verificar si el profesional atiende por la mutual seleccionada.'
      });
    }
    this.loading = false;
    this.cargando = false;
  }

  async obtenerDatosUsuario() {
    await this.userService.getUserData().toPromise().then(async (respuesta) => {
      // console.log(respuesta)
      this.turnoAutorizar.paciente = respuesta;

      await this.userService.obtenerCoberturasPaciente().toPromise().then(async (rpCoberturas) => {
        console.log(rpCoberturas)
        this.mutuales = rpCoberturas;
        this.mutualElegida = this.mutuales[0];
        
        await this.verificarMutualProf(this.mutualElegida);
      }, (error) => {
        console.log(error);
        this.openDialogMsje("Ocurrio un error al obtener sus coberturas. Intente nuevamente.");
      });
    }, (error) => {
      console.log(error);
      this.openDialogMsje("Ocurrio un error. Intente nuevamente.");
    });
  }

  async obtenerDatosTurno() {
    this.turnoAutorizar = await this.turnoService.getTurnoAdmision();
    if (this.turnoAutorizar && this.turnoAutorizar != null && this.turnoAutorizar != undefined) {
      // console.log(this.turnoAutorizar);
      // this.turnoAutorizar.servicio == 'Consultorio' ? this.esConsultorio = true : this.esConsultorio = false;
      await this.obtenerDatosUsuario();
    } else {       // SI NO SE OBTIENEN LOS DATOS DEL TURNO MUESTRO MSJE Y REDIRECCIONO A 'MIS RESERVAS'
      this.openDialogMsje("Ocurrio un error al obtener los datos del turno. Intente nuevamente.");
    }
  }

  openDialogMsje(msje: string) {
    this.dialog.open(DialogMensajeComponent, {
      data: {
        ok: false,
        mensaje: msje
      },
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().toPromise().then(() => {
      this.turnoService.setTurnoAdmision(null);
      this.router.navigate(['turnos-reservados']);
    });
  }

  cancelar() {
    this.turnoService.setTurnoAdmision(null);
    this.router.navigate(['turnos-reservados']);
  }

  async cambioMutual(mutualElegida) {
    // console.log(mutualElegida);
    this.invalidForm = false;
    this.mutualElegida = mutualElegida;
    await this.verificarMutualProf(mutualElegida);
  }

  confirmarAutorizacion() {
    this.invalidForm = false;
    // console.log(this.turnoAutorizar);
    // console.log(this.mutualForm);
    if (this.mutualForm.invalid) {
      this.invalidForm = true;
      this.msjeInvalidForm = 'Complete todos los datos.'
    } else {
      this.turnoAutorizar.mutualElegida = this.mutualElegida;
      this.turnoAutorizar.nroCarnet = this.mutualForm.get('nroCarnet').value;
      this.turnoAutorizar.pinSeguridad = this.mutualForm.get('pinSeguridad').value;
      this.turnoAutorizar.urlPagoMp = this.urlPagoMP;
      this.turnoService.setTurnoAdmision(this.turnoAutorizar);
      this.dialog.open(DialogAutorizarCoberturaComponent, {
        hasBackdrop: true,
        maxHeight: 560
      })
    }
  }

}