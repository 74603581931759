import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { Respuesta, DialogMensajeComponent } from './../dialog-mensaje/dialog-mensaje.component';
import { UserData } from '../header/header.component';
import { User } from '../_models/user';
import { UserService } from '../_services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { DialogActualizarContrasenaComponent } from '../dialog-actualizar-contrasena/dialog-actualizar-contrasena.component';
import { SplitStringPipe } from '../_pipes/split-string.pipe';
import heic2any from "heic2any";

@Component({
  selector: 'app-actualizar-datos',
  templateUrl: './actualizar-datos.component.html',
  styleUrls: ['./actualizar-datos.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: SplitStringPipe},
  ],
})

export class ActualizarDatosComponent implements OnInit {
  fecha_max: Date;
  currentUser: User;
  userData: UserData;
  cargado = false;
  form: FormGroup;
  submitted: boolean;
  loading: boolean;
  respuesta: Respuesta;
  fechaNacimientoString: string;
  hay_modificacion: boolean = false;
  fotoPaciente:String;
  modificadaFoto: boolean = false;
  loadingSubmit: boolean = false;

  constructor(
    private userService: UserService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private splitString: SplitStringPipe,
    private authenticationService:AuthenticationService,
  ) {
    var link = function (scope, element, attrs) {
      const input = element.find('#fileInput');
      const button = element.find('#uploadButton');
  
      if (input.length && button.length) {
          button.click((e) => input.click());
      }
    }

    this.fecha_max = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.currentUser = this.authenticationService.currentUserValue;
    if (localStorage.getItem('currentUser') != null) {
      this.userService.getUserData().subscribe((x) => {
        // console.log(x);
        this.userData = x;
        this.initForm(this.userData);
        this.cargado = true;
      }, error => {
        console.log(error);
      });
    }
  }

  ngOnInit() {
    this.hay_modificacion = false;  
    this.authenticationService.fotoActual.subscribe(fotoPaciente => this.fotoPaciente = fotoPaciente);
  }

  // Funcion que actualiza los datos en la BD.
  saveData() {
    this.dialog.open(DialogConfirmacionComponent, {
      data: {
        titulo: 'Confirmar',
        mensaje: 'actualizar tus datos',    
        estasPor: true           
      },
      panelClass: 'dialog-sin-overflow',
    }).afterClosed().toPromise().then(async (confirmado: boolean) => {
      if (confirmado) {
        this.loadingSubmit = true;
        this.submitted = true;
        if (this.form.valid) {
          console.log('form valid');
          
          const fechaNacimiento = new Date(this.form.value.fechaNacimiento);
          const formattedDate = fechaNacimiento.toISOString().split('T')[0];

          await this.userService.actualizarDatos(this.form.value, formattedDate).toPromise().then(res => {
            // console.log(res)
            this.loadingSubmit = false;
            this.openDialog(res);
            this.hay_modificacion = false;
            if (this.userData.campania_actualizacion == 'N')
              this.userData.campania_actualizacion = 'S';
              this.currentUser.campaniaActualizacion = 'S';
              localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
              this.userService.actualizarFlagUsuarioCampania().subscribe((resp) => {
                // console.log(resp)
              }, (errorR) => {
                console.log(errorR);
              },)
              if (this.modificadaFoto) {
                this.userService.getUserData().subscribe(
                  async (data) => {
                    this.authenticationService.cambiarFotoPerfil(data.fotoPerfil);
                    this.modificadaFoto = false;
                  }
                )
              }
          }, (error) => {
            console.log('error: ' + error);
            this.openDialog({ok: false, mensaje: 'Ocurrio un error al querer guardar los datos.'})
            this.loadingSubmit = false;
          });
          this.submitted = false;
        } else {
          // console.log('form INVALID');
          this.loadingSubmit = false;
        }
      }
    });
  }

  private blobToFile = (theBlob: Blob, fileName:string): File => {
    let b: any = theBlob;
  
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModified = new Date();
    b.name = fileName;
  
    //Cast to a File() type
    return <File>theBlob;
  }

  async onFileChange(event) {
    this.hay_modificacion = false;
    this.loadingSubmit = true;
    const reader = new FileReader();
    const maxSize = 10*1024*1024;         // 10 MB
    let validado = false;

    ////////////
    // CONVERT HEIC TO JPG
    let file:File = event.target.files[0];
    let blob:Blob = event.target.files[0];
    let convProm:any;
    
    if (event.target.files[0].name.includes(".HEIC") || event.target.files[0].name.includes(".heic")) {      
      convProm = await heic2any( {blob, toType:"image/jpeg", quality:.2} )
      .then((jpgBlob:Blob) => {
          // console.log(jpgBlob);
        
          //Change the name of the file according to the new format
          let newName = file.name.replace(/\.[^/.]+$/, ".jpeg");

          //Convert blob back to file
          file = this.blobToFile(jpgBlob,newName);
      }).catch(err => {
        console.log(err);        
      });
    } else {
      //This is not a HEIC image so we can just resolve
      convProm = Promise.resolve(true);
    }
    ////////////
 

    if (event.target.files && event.target.files.length > 0 && 
      (new String(file.type).match(/image.*/))) {
      // console.log(event.target.files[0].size)
      if(file.size > maxSize) {
          this.dialog.open(DialogMensajeComponent, {
            width: '500px',
            data: {
              ok: false,
              mensaje: 'El archivo es demasiado grande',
            },
            panelClass: 'dialog-sin-overflow'
          })
          event.target.value = null;
      } else {
        validado = true;
      }

      if (validado) {
        this.form.get(event.target.getAttribute('id')).setErrors({ archivoInvalido: false });
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.form.get(event.target.getAttribute('id')).setValue({
            nombre: new String(this.userData.usuario+'.'+ this.splitString.transform(file.name)),
            tipo: '.' + this.splitString.transform(file.name),
            valor: (reader.result as string).split(',')[1],
          });
        };
        this.modificadaFoto = true;    
        this.hay_modificacion = true;    
        this.loadingSubmit = false;
      }
    } else {
      this.form.get(event.target.getAttribute('id')).setErrors({ archivoInvalido: true });
    }
  }

  openDialog(res: Respuesta): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: res,
      panelClass: 'dialog-sin-overflow'
    });    
    dialogRef.afterClosed().subscribe(() => {
      if (res.ok) {        
       // this.router.navigate(['/turnos']);
      }
    });
  }

  get getForm() {
    return this.form.controls;
  }

  initForm(userData) {
    // console.log(userData)
    let fecha = new Date(userData.fechaNacimiento);
    let mes = fecha.getMonth()+1;
    this.fechaNacimientoString = fecha.getDate() +'/'+ mes +'/'+ fecha.getFullYear();

    this.form = this.fb.group({
      email: [userData.email, [Validators.email, Validators.required, ], ],
      telefono: [userData.telefonoParticular, [Validators.pattern('[0-9]*'), Validators.minLength(7), Validators.maxLength(20), Validators.required], ],
      fechaNacimiento: [userData.fechaNacimiento, [Validators.required]],
      fotoPerfil: [''],
      domicilio: [userData.domicilio, [Validators.pattern(/^[a-zA-Z0-9 - \u00E0-\u00FC \u00C0-\u00DC \u00f1 \u00d1 ° . / \- # *]+$/), Validators.required, ], ],
      localidad: [userData.localidad, [Validators.pattern(/^[a-zA-Z0-9 - \u00E0-\u00FC \u00f1 \u00d1]+$/), Validators.required]],
      cp: [userData.codigoPostal, [Validators.pattern(/^[0-9]+$/), Validators.required]],
      nombreFotoPerfil: [null],
    });
  }

  getErrorMessageEmail() {
    return this.form.controls.email.hasError('required') ? 'Este campo es obligatorio' :
        this.form.controls.email.hasError('email') ? 'Ingrese un email valido' :
            'Ingrese un email válido';
  }

  getErrorMessageTelefono() {
    return this.form.controls.telefono.hasError('pattern') ? 'Ingrese solo números' :
          this.form.controls.telefono.hasError('minlength') ? 'Ingrese un numero de al menos 7 dígitos' : 
          this.form.controls.telefono.hasError('required') ? 'Este campo es obligatorio' : '';
  }

  getErrorMessageFechaNac() {
  let regexp = new RegExp(/^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/gm);
  
  var fecha = new Date(this.form.controls.fechaNacimiento.value);
  var mes = fecha.getMonth()+1;
  this.fechaNacimientoString = fecha.getDate() +'/'+ mes +'/'+ fecha.getFullYear();
  
  let formato_correcto = regexp.test(this.fechaNacimientoString);

  return formato_correcto ? 'Ingrese dia/mes/año o utilice el calendario' : 
    this.form.controls.fechaNacimiento.hasError('required') ? 'Este campo es obligatorio' : '';
  }

  getErrorMessageDomicilio() {
    return this.form.controls.domicilio.hasError('pattern') ? 'Ingrese caracteres válidos' :
    this.form.controls.domicilio.hasError('required') ? 'Este campo es obligatorio' : '';
  }

  getErrorMessageLocalidad() {
    return this.form.controls.localidad.hasError('pattern') ? 'Ingrese solo letras y números' : 
    this.form.controls.localidad.hasError('required') ? 'Este campo es obligatorio' : '';
  }

  getErrorMessageCP() {
    return this.form.controls.cp.hasError('pattern') ? 'Ingrese solo números' : 
    this.form.controls.cp.hasError('required') ? 'Este campo es obligatorio' : '';
  }
  
  abrirDialogActualizarContrasena(mostrarCancel) {
    this.dialog
    .open(DialogActualizarContrasenaComponent, {
      width: '500px',
      hasBackdrop: true,
      data:{mostrarCancelar:mostrarCancel},
      panelClass: 'dialog-sin-overflow'
    }).afterClosed()
    .subscribe(
      (cambiado: boolean) => {
        if (cambiado) {
          //this.goTurnos();
        } 
      },
      (error) => {
        console.log(error);
      }
    );
  }

  abrirDialogBorrarUsuario(){
    this.dialog
    .open(DialogConfirmacionComponent, {
      data: {
        titulo: 'Confirmar borrado',
        mensaje: 'borrar tu usuario',        
        esperarTimer: true,
        estasPor: true
      },      
      disableClose: true,
      hasBackdrop: true,
      enterAnimationDuration: '.5s',
      panelClass: 'dialog-sin-overflow',
    }).afterClosed().subscribe(
      decision => {
        if(decision){ // si apreta el Confirmar
          this.userService.cambiarEstado(this.userData.usuario, 'INACTIVO').subscribe(
            x => {
              console.log(x);
              if(x.ok){
                this.dialog.open(DialogMensajeComponent, {
                  data:{
                    ok: true,
                    mensaje: 'El usuario ha sido borrado correctamente.'
                  },
                  hasBackdrop: true,
                  disableClose: true,
                  panelClass: 'dialog-sin-overflow'
                }).afterClosed().subscribe(
                  () => {
                    window.location.href = '/login';
                  }
                )
              } else {
                this.dialog.open(DialogMensajeComponent, {
                  data:{
                    ok: false,
                    mensaje: 'El usuario no pudo ser borrado. Intente nuevamente más tarde.'
                  },
                  hasBackdrop: true,
                  disableClose: true,
                  panelClass: 'dialog-sin-overflow'
                })
              }
            },
            error => {
              console.log(error);              
            }
          )
          
        }
        
      }
    )
  }

  goTurnos() {
    if (this.currentUser) {
     // this.router.navigate(['/turnos']);
    }
  }

  hayModificacion(){
    this.hay_modificacion = true;    
    this.form.markAllAsTouched();
  }

  formularioInvalido(){
    let invalido = false;
    
    if (this.getForm.fotoPerfil.errors ||
      this.getForm.domicilio.invalid ||
      this.getForm.cp.invalid ||
      this.getForm.localidad.invalid || 
      this.getForm.fechaNacimiento.invalid ||
      this.getForm.telefono.invalid ||
      this.getForm.email.invalid
    ) {
      invalido = true;
    }

    return invalido;
  }


}
